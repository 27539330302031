<template>
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <h2 class="brand-text text-primary ml-1">Monica</h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">Access denied 🚫️</h2>
        <p class="mb-2">
          Oops! 😖 you are not authorized to accesses this page, or we could not authenticate you. Try again and if
          the problem persists, please contact an administrator.
        </p>
        <b-button :to="{path:'/login'}" class="mb-2 btn-sm-block" variant="primary">Back to login page</b-button>
        <b-img :src="imgUrl" alt="Error page" fluid/>
      </div>
    </div>
  </div>
</template>

<script>
import {BButton, BImg, BLink} from 'bootstrap-vue';
import VuexyLogo from '@core/layouts/components/Logo.vue';
import store from '@/store';
import {computed} from "@vue/composition-api/dist/vue-composition-api";

export default {
  components: {
    VuexyLogo,
    BLink,
    BButton,
    BImg,
  },
  setup() {
    let imgUrl = require('@/assets/images/pages/not-authorized.svg');

    computed(() => {
      if (store.state.appConfig.layout.skin === 'dark') {
        imgUrl = require('@/assets/images/pages/not-authorized-dark.svg');
      }
    });

    return {imgUrl};
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
